import React from "react"
import { Link } from "gatsby"

import Layout from "src/components/layout"
import SEO from "src/components/seo"
import PageTitle from "src/components/page-title"

import gumtreeImage from "src/images/case-study-images/luke-todd@2x.jpg"
import jesper from "src/images/case-study-images/jesper_weltstrom.png"
import lara from "src/images/case-study-images/lara_fernandez.png"
import gengoLogo from "src/images/case-study-images/gengo_logo_white@2x.png"
import klubmodulLogo from "src/images/case-study-images/klubmodul-logo-white.png"

function IndexPage() {
  return (
    <Layout>
      <SEO title="Case Studies" />
      <PageTitle
        preheader="Nickelled In Action"
        title="Case Studies"
        subtitle="Find out more about how Nickelled transforms businesses"
      ></PageTitle>
      <div class="bg-white pt-16 lg:py-24">
        <Link to="/case-studies/gumtree/">
          <div class="pb-16 bg-oOrange lg:pb-0 lg:z-10 lg:relative">
            <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
              <div class="relative lg:-my-8">
                <div
                  aria-hidden="true"
                  class="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden"
                ></div>
                <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
                  <div class="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                    <img
                      class="object-cover lg:h-full lg:w-full"
                      src={gumtreeImage}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div class="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
                <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
                  <blockquote>
                    <div>
                      <svg
                        class="h-12 w-12 text-white opacity-25"
                        fill="currentColor"
                        viewBox="0 0 32 32"
                        aria-hidden="true"
                      >
                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                      </svg>
                      <p class="mt-6 text-2xl font-medium text-white">
                        We love the way Nickelled lets us show users around our
                        site. It took us no time to get up and running.
                      </p>
                    </div>
                    <footer class="mt-6">
                      <p class="text-base font-medium text-white">Luke Todd</p>
                      <p class="text-base font-medium text-white">
                        Head of Customer Operations, Gumtree
                      </p>
                    </footer>
                  </blockquote>
                  <div class="inline-block bg-white text-oOrange font-semibold px-4 py-3 hover:bg-oOrange-light rounded-md">
                    Read Case Study
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <section class="bg-oOrange mb-16">
        <div class="max-w-7xl mx-auto md:grid md:grid-cols-2 md:px-6 lg:px-8">
          <Link to="/case-studies/klubmodul/">
            <div class="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-oOrange lg:pr-16">
              <div class="md:flex-shrink-0">
                <img class="h-12" src={klubmodulLogo} alt="Klubmodul" />
              </div>
              <blockquote class="mt-6 md:flex-grow md:flex md:flex-col">
                <div class="relative text-lg font-medium text-white md:flex-grow">
                  <svg
                    class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-white opacity-25"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                    aria-hidden="true"
                  >
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p class="relative">
                    Today an agent in the support team can handle 600 clubs.
                    That is just outstanding, just two years ago, an agent would
                    handle only 300 clubs!
                  </p>
                </div>
                <footer class="mt-8">
                  <div class="flex items-start">
                    <div class="flex-shrink-0 inline-flex rounded-full border-2 border-white">
                      <img class="h-12 w-12 rounded-full" src={jesper} alt="" />
                    </div>
                    <div class="ml-4">
                      <div class="text-base font-medium text-white">
                        Jesper Weltström
                      </div>
                      <div class="text-base font-medium text-white">
                        CEO, Klubmodul
                      </div>
                    </div>
                  </div>
                  <div class="inline-block bg-white text-oOrange font-semibold px-4 py-3 mt-4 hover:bg-oOrange-light rounded-md">
                    Read Case Study
                  </div>
                </footer>
              </blockquote>
            </div>
          </Link>
          <Link to="/case-studies/gengo/">
            <div class="py-12 px-4 border-t-2 border-oOrange sm:px-6 md:py-16 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16">
              <div class="md:flex-shrink-0">
                <img class="h-12" src={gengoLogo} alt="Gengo" />
              </div>
              <blockquote class="mt-6 md:flex-grow md:flex md:flex-col">
                <div class="relative text-lg font-medium text-white md:flex-grow">
                  <svg
                    class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-white opacity-25"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                  >
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p class="relative">
                    Nickelled was the only company that we found where we could
                    create tutorials to our liking without needing to add HTML
                    code to our website.
                  </p>
                </div>
                <footer class="mt-8">
                  <div class="flex items-start">
                    <div class="flex-shrink-0 inline-flex rounded-full border-2 border-white">
                      <img class="h-12 w-12 rounded-full" src={lara} alt="" />
                    </div>
                    <div class="ml-4">
                      <div class="text-base font-medium text-white">
                        Lara Fernandez
                      </div>
                      <div class="text-base font-medium text-white">
                        Community Manager, Gengo
                      </div>
                    </div>
                  </div>
                  <div class="inline-block bg-white text-oOrange font-semibold px-4 py-3 mt-4 hover:bg-oOrange-light rounded-md">
                    Read Case Study
                  </div>
                </footer>
              </blockquote>
            </div>
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
